@media (max-width: 576px) {
  .logo {
    width: 170px;
  }

  .about__img {
    display: none;

    &--2 {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }
  }

  .about {
    .text {
      padding: 15px 15px 15px 0;
      background: rgba(#ffffff, 0.64);
    }
  }

  .text {
    font-size: 0.9rem;
  }

  .mt-5 {
    margin-top: 2rem !important;
  }

  .mb-5 {
    margin-bottom: 2rem !important;
  }

  .footer-menu--mobile a {
    margin: 15px 0;
    width: 48%;
    text-align: center;
  }

  .footer-menu--mobile {
    padding: 15px 0;
  }

  .footer-logo {
    width: 120px;
  }

  .footer-sosyal strong {
    display: none;
  }

  .about-figure {
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px;
    background: $renkss;
    text-align: center;
  }

  .about-figure__icon {
    width: 80px;
  }

  .about-figure__title {
    margin: 0;
    margin-top: 15px;
    padding: 15px 0 0;
    width: 100%;
    border: none;
    border-top: 1px solid $renkss;
    font-size: 7vw;

    small {
      font-size: 3vw;
    }
  }

  .timeline ol,
  .timeline ol li {
    width: auto !important;
  }

  .timeline ol {
    padding: 210px 0 0;
    transform: none !important;
  }

  .timeline ol li {
    display: block;
    margin-bottom: 30px !important;
    height: auto;
    background: transparent;
  }

  .timeline ol li:first-child {
    margin-top: 25px;
  }

  .timeline ol li:not(:first-child) {
    margin-left: auto;
  }

  .timeline ol li div {
    top: 0 !important;
    margin: 0 auto 25px;
    width: 94%;
    height: auto !important;
  }

  .timeline ol li div {
    position: static;
  }

  .timeline ol li:nth-child(odd) div {
    transform: none;
  }

  .timeline ol li:nth-child(even) div::before,
  .timeline ol li:nth-child(odd) div::before {
    top: 100%;
    left: 50%;
    height: 25px;
    border: none;
    border-left: 1px solid white;
    transform: translateX(-50%);
  }

  .timeline .arrows,
  .timeline ol li:last-child,
  .timeline ol li:not(:last-child)::after,
  .timeline ol li:nth-last-child(2) div::before {
    display: none;
  }

  .timeline {
    padding-bottom: 0;
  }

  .timeline ol {
    padding-top: 15px;
  }

  .timeline ol li:nth-child(even) div::before,
  .timeline ol li:nth-child(odd) div::before {
    height: 33px;
    border-color: $renk_koyumavi;
  }

  .timeline ol li div {
    transform: none !important;
  }

  .hakkimizda-resim {
    position: relative;
    width: 100%;
    height: 220px;
  }

  .h-bolum {
    padding: 0;

    .row {
      flex-direction: column-reverse;
    }
  }

  .h-bolum-23 {
    margin-top: 30px;
    padding-bottom: 30px;

    .row {
      flex-direction: column;
    }
  }

  .mt75 {
    margin-top: 40px;
  }

  .mb75 {
    margin-bottom: 40px;
  }

  .map-section {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 30px 0;
  }

  .reference-list{
    li{
      width: 50%;
      font-size: .8rem;
      padding: 15px;
    }
  }

  .phone{
    font-size: .7rem;
  }

  .slider-detail__title{
    font-size: 1.1rem;
  }

  .slider-detail__text{
    font-size: .8rem;
    margin-bottom: 15px;
  }

  .header__flex{
    height: 65px;
  }

  .header--fixed .header__flex{
    height: 65px;
  }
}
