.btn-mobil {
  padding: 0;
  position: relative;
  top: 4px;
  outline: transparent;
}

.arka-active {
  display: block;
  opacity: 1;
}

.mobil-nav--icon {
  display: block;
  height: 25px;
}

.mobil {
  display: block;
  position: fixed;
  top: 0;
  left: -320px;
  background: $renk_koyumavi;
  color: #fff;
  width: 300px;
  height: 100%;
  max-width: calc(100% - 80px);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  z-index: 9999;

  strong{
    font-weight: 500;
  }

  .nav-tabs {
    display: flex;
    position: absolute;
    bottom: 0;
    font-weight: 500;
    background: #00152b;
    width: 100%;
    z-index: 99999;

    li {
      width: 50%;
      text-align: center;
    }
  }

  .tab {
    overflow: hidden;
  }

  .iletisim-bilgileri {
    padding: 30px;
    line-height: 1.7;

    .iletisim-box {
      font-size: 0.85rem;
      padding-left: 0;
    }

    span {
      opacity: 0.7;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .tablinks {
    display: block;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 15px 0;
    padding-top: 18px;
    border-bottom: 3px solid transparent;
    opacity: 0.6;
    transition: all 0.3s;

    &:hover {
      border-color: $renk_mavi;
      opacity: 0.8;
    }

    &.active {
      border-color: $renk_mavi;
      opacity: 1;
    }
  }

  .tabcontent {
    padding-bottom: 45px;
    overflow-y: scroll;
    height: calc(100vh - 75px);
  }
}

.mobil-logo {
  padding: 30px 0;
  border-bottom: 1px solid $renksb;

  img {
    display: block;
    margin: 0 auto;
    width: 160px;
  }
}


.mobil-menu-li {

  .btn-atik{
    display: block;
    padding: 15px 30px;
    width: auto;
    margin: 0 auto !important;
    width: 230px !important;
    margin-top: 30px !important;
  }
}

.mobil-menu-link {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  padding: 20px 20px 20px 35px;
  transition: all 0.3s;
  text-decoration: none !important;

  i {
    margin-right: 6px;
  }

  &:focus,
  &:hover {
    background: $renksb;
    color: $renk_mavi;
  }

  &.active {
    background: $renksb;
    color: $renk_mavi;
  }
}

.mobil-alt-menu {
  display: none;
  background: $renkss-3;
  padding: 15px 0;

  ul {
    padding: 10px 0;
    line-height: 1.4;
  }

  a {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: rgba(#fff, .8);
    padding: 12px 12px 12px 50px;
    text-decoration: none;
    @include efekt(color);

    &:Hover {
      color: $renk_mavi;
    }

    &.active {
      color: $renk_mavi;
    }
  }
}

.mobil-active {
  left: 0;
}

.mobil-tab{
  height: calc(100vh - 80px);
  overflow-y: scroll;
}
