@media (max-width: 992px) {
  .entry-wrapper {
    .form-wrapper {
      display: none;
    }
  }

  .slider-detail {
    width: 100%;
    bottom: 0;
    position: relative;
    padding: 15px;
    padding-bottom: 100px;
  }

  .slider-nav {
    bottom: 15px;
    padding-right: 15px;
    width: 100%;
  }

  .container,
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }

  .phone--header {
    right: 15px;
  }

  .swiper-pagination-progressbar.swiper-pagination-horizontal {
    left: 100px;
    width: calc(100% - 250px);
  }

  .project__text {
    font-size: 0.9rem;
  }

  .title small {
    margin-top: 5px;
    font-size: 1rem;
  }

  #responsive-slider .swiper-button-next,
  #responsive-slider .swiper-button-prev {
    width: 40px;
    height: 40px;
  }

  #responsive-slider .swiper-button-next:after,
  #responsive-slider .swiper-button-prev:after {
    font-size: 1.2rem;
  }

  .about__left {
    padding: 15px;
  }

  .about__img {
    bottom: 0;
    margin-top: 30px;
    max-width: 80%;
    width: 80%;
  }

  .about {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .footer-adres {
    width: 220px;
  }

  .footer-bulten {
    width: 250px;
  }

  .hero__title {
    font-size: 3rem;
  }

  .about-figure__icon {
    width: 50px;
  }

  .about-figure__title {
    margin-left: 15px;
    padding-left: 15px;
  }

  .about-figure__title {
    font-size: 5.5vw;

    small {
      font-size: 2vw;
    }
  }

  .about-entry__bottom {
    padding-bottom: 15px;
  }

  .h-yazi {
    font-size: 0.9rem;
  }

  .h-bolum {
    padding: 15px 0;
  }

  .project-hero__img {
    height: 60vh;
  }

  .project-hero .slider-detail {
    padding-right: 15px;
  }

  .project-hero .down-arrow {
    margin-top: 0;
  }

  .project-hero .slider-detail {
    bottom: 30px;
  }

  .types__left {
    margin-bottom: 30px;
    width: 100%;
  }

  .types__right {
    width: 100%;
  }

  .reference-list{
    li{
      width: 33.333%;
    }
  }
}
