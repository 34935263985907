@media (max-width: 768px) {
  .menu {
    display: none;
  }

  .btn-mobil {
    display: inline-block;
  }

  .slider-detail__title {
    font-size: 1.6rem;
  }

  .slider-detail__text {
    font-size: 0.9rem;
  }

  .project__logo {
    width: 70px !important;
  }

  .link {
    font-size: 0.9rem;

    &:before {
      width: 20px;
    }
  }

  #responsive-slider .swiper-button-next,
  #responsive-slider .swiper-button-prev {
    display: none;
  }

  .project {
    padding: 15px;

    &--page {
      padding: 0;
    }
  }

  .down-arrow {
    width: 40px;

    svg {
      height: 40px;
    }
  }

  .swiper-button {
    svg {
      width: 30px;
    }
  }

  .swiper-pagination-progressbar.swiper-pagination-horizontal {
    left: 70px;
    width: calc(100% - 210px);
  }

  .footer-adres {
    margin-bottom: 30px;
    width: 100%;
    text-align: center;
  }

  .footer-line {
    margin-right: auto;
    margin-left: auto;
  }

  .footer-bulten {
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    padding: 30px;
    max-width: 100%;
    width: 300px;
    background: $renkss;
    text-align: center;

    .text-right {
      text-align: center;
    }
  }

  .footer-genel {
    width: 100%;
    text-align: center;
  }

  .tab-nav--category {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  .tab-nav--category .tab-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 2%;
    padding: 10px 15px;
    width: 49%;
    border: 1px solid #dfdfdf !important;
    text-align: center;
    font-size: 1rem;
    font-size: 0.9rem;

    // &:last-child {
    //   width: 100%;
    // }
  }

  .hero__title {
    font-size: 2rem;
  }

  .hero {
    padding-top: 150px;
  }

  .contact__left {
    width: 100%;
  }

  .contact__map {
    width: 100%;
    height: 300px;
  }

  .contact__info {
    margin: 40px 0;
  }

  .formContact .form {
    padding: 15px;
  }

  .formContact .form__label {
    width: 100%;
    font-size: 0.9rem;
  }

  .formContact .form__item .form__text {
    width: 100%;
  }

  .form-onay {
    font-size: 0.8rem;
  }

  .about-entry__bottom {
    margin-top: 30px;
  }

  .about-entry__bottom:before {
    height: 90%;
  }

  .timeline {
    padding: 30px 0;
  }

  .timeline ol li div {
    padding: 15px;
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .h-yazi {
    padding: 15px 0;
  }

  .product__carousel .gallery-thumbs .swiper-slide img {
    height: 50px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 1rem;
  }

  .contact-text {
    font-size: 0.9rem;
  }

  .about-message__img{
    width: 300px;
    max-width: 100%;
  }

  .about-message p{
    font-size: .9rem;
  }

  .slider-detail{
    padding-bottom: 70px;
    padding-left: 0;
    padding-right: 0;
  }

  .phone--header{
    bottom: -25px;
    display: none;
  }

  .phone--mobile{
    display: block;
  }
}
