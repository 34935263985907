@import "reset", "colors", "mixin", "global", "hamburgers", "bootstrap-grid", "swiper", "typography", "buttons", "forms";

.btn-mobil,
.mobil {
  display: none;
}

.arama-bg,
.mobil-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: $renkss;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.arka-active {
  display: block;
}

.swiper-slide {
  img {
    width: 100%;
  }
}

.container-fluid {
  position: relative;
  padding-right: 75px;
  padding-left: 75px;
}

.container {
  padding-right: 75px;
  padding-left: 75px;
}

.header {
  @include efekt(background);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  background: rgba(24, 54, 67, 0.5);
  backdrop-filter: blur(4px);

  .menu__link {
    color: #fff;
  }

  .lang-select {
    color: #fff;
  }

  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before {
    background: #fff;
  }

  &__flex {
    @extend .flex;
    @include efekt(height);
    height: 120px;
    border-bottom: 1px solid $renksb;
  }

  &--page {
    background: #fff;

    .header__flex {
      border-bottom: 1px solid $renkss;
    }

    .menu__link {
      color: $renk_koyumavi;
    }

    .lang-select {
      color: $renk_koyumavi;
    }

    .phone--header {
      color: $renk_koyumavi;
    }

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background: $renk_koyumavi;
    }

    .phone {
      text-shadow: none;

      a{
        color: $renk_mavi;
      }
    }
  }

  &--fixed {
    background: rgba(24, 54, 67, 0.5);
    backdrop-filter: blur(4px);

    .header__flex {
      height: 80px;
    }

    .menu__link {
      height: 80px;
      color: #fff;
      line-height: 80px;
    }

    .lang-select {
      color: #fff;
    }

    .logo {
      img {
        width: 150px;
      }
    }

    .phone--header {
      display: none;
    }

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background: #fff;
    }
  }
}

.hamburger--3dx .hamburger-inner,
.hamburger--3dx .hamburger-inner::after,
.hamburger--3dx .hamburger-inner::before {
  transition: all 0.3s !important;
}

.logo {
  display: inline-block;

  img {
    @include efekt(width);
    width: 300px;
  }
}

.menu {
  &__list {
    @extend .flex;
  }

  &__item {
    margin-left: 5vw;
    font-size: 1.2rem;
  }

  &__link {
    @extend .efekt;
    position: relative;
    display: block;
    height: 120px;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    line-height: 120px;

    &:after {
      @include efekt(opacity);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $renk_mavi;
      content: "";
      opacity: 0;
    }

    &:hover {
      color: #33a893;

      &:after {
        opacity: 1;
      }
    }
  }
}

.lang-select {
  outline: transparent;
  border: none;
  background: none;
  color: #fff;
  font-weight: 600;
  font-weight: 300;
  font-size: 1.2rem;

  option {
    color: $renk_koyumavi;
  }
}

.phone {
  @include efekt(color);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
  text-decoration: none;
  text-shadow: 2px 2px 3px #000;

  .bi-dash-lg {
    margin: 0 4px;
  }

  a{
    text-decoration: none;
    color: #fff;
  }

  &:hover {
    a{
      color: $renk_mavi;
    }
  }

  &--header {
    position: absolute;
    right: 75px;
    bottom: -35px;
    color: #fff;
  }
}

.entry-wrapper {
  position: relative;
}

.home-slider {
  position: relative;

  .swiper-slide {
    img {
      width: 100%;
    }
  }

  .container-fluid {
    @extend .flex;
  }
}

.slider-nav {
  position: absolute;
  bottom: 50px;
  left: 0;
  z-index: 10;
  width: 100%;
}

.slide-progress {
  max-width: 100%;
  width: 0;
  height: 4px;
  background: #fff;
}

.slide-progress-main {
  width: calc(100% - 90px);
  background: $renksb-2;
}

.swiper-pagination-progressbar {
  height: 3px;
  background: $renksb-4;
}

.swiper-pagination-progressbar.swiper-pagination-horizontal {
  top: 50%;
  left: 200px;
  width: calc(100% - 430px);
  height: 2px;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #fff;
}

.swiper-button {
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;

  line,
  path {
    @include efekt(stroke);
  }

  &:hover {
    line,
    path {
      stroke: $renk_mavi;
    }
  }
}

.down-arrow {
  display: inline-block;

  circle {
    @include efekt(stroke);
  }

  path {
    @include efekt(fill);
  }

  &:hover {
    circle {
      stroke: $renk_mavi;
    }

    path {
      fill: $renk_mavi;
    }
  }
}

.form-wrapper {
  position: absolute;
  right: 75px;
  bottom: 50px;
  z-index: 3;
  padding: 30px;
  width: 460px;
  background: #fff;

  .title {
    margin-bottom: 5px;
    color: #0b0b0b;
    font-size: 1.5rem;
    font-family: $libre;
  }

  .yazi {
    font-size: 0.9rem;
  }
}

.txtBulten {
  @include efekt(border-color);
  display: block;
  width: 100%;
  height: 44px;
  outline: transparent;
  border: none;
  border-bottom: 1px solid $renk_yazi;
  background: none;

  &--area {
    height: 70px;
  }

  &:focus,
  &:hover {
    border-color: $renk_mavi;
  }
}

.custom-control-label {
  font-size: 0.8rem;
}

.slider-detail {
  position: absolute;
  bottom: 20%;
  left: 0;
  color: #fff;
  background: $renk_koyumavi;
  padding: 30px;

  &__logo {
    display: block;
    margin-bottom: 30px;
    width: 180px;
  }

  &__title {
    display: block;
    font-weight: 500;
    font-size: 3.5rem;
    font-family: $libre;
  }

  &__text {
    display: block;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 2rem;
  }

  .container-fluid {
    display: block;
    padding-right: 0;
  }
}

.link {
  @include efekt(color);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  text-decoration: none;
  font-weight: 200;
  font-size: 1.4rem;

  &:before {
    @extend .efekt;
    display: inline-block;
    margin-right: 10px;
    width: 30px;
    height: 1px;
    background: #fff;
    content: "";
  }

  &:hover {
    color: $renk_mavi;

    &:before {
      width: 40px;
      background: $renk_mavi;
    }
  }

  &--blue {
    color: $renk_koyumavi;

    &:before {
      background: $renk_koyumavi;
    }
  }
}

.project {
  padding: 30px;
  background: #f6f7fb;

  a {
    color: inherit;
    text-decoration: none;
  }

  &--page {
    padding: 0;
    background: none;
  }

  &__img {
    margin: 25px 0;
  }

  &__logo {
    width: 120px !important;
  }

  &__title {
    @include efekt(color);
    display: block;
    margin: 25px 0;
    font-size: 1.8rem;
    font-family: $libre;
  }

  &__text {
    display: block;
    margin: 25px 0;
  }

  &:hover {
    .link,
    .project__title {
      color: $renk_mavi;
    }

    .link {
      &:before {
        background: $renk_mavi;
      }
    }
  }
}

#responsive-slider {
  padding-bottom: 50px;

  .swiper-button-next,
  .swiper-button-prev {
    width: 60px;
    height: 60px;
    background: rgba($renk_koyumavi, 0.4);
    transition: background 0.3s, color 0.3s;
    backdrop-filter: blur(4px);

    &:after {
      font-size: 1.5rem;
    }

    &:hover {
      background: rgba($renk_mavi, 0.4);
      color: $renk_koyumavi;
    }
  }
}

.about {
  padding-top: 100px;

  &__right {
    text-align: right;
  }

  &__left {
    display: -webkit-inline-flex;
    display: -ms-inline-flex;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    padding: 10%;

    .title {
      width: 100%;
    }
  }

  &__img {
    position: relative;
    bottom: -70px;
    margin-left: -10%;
    max-width: 120%;
    width: 120%;
  }
}

.footer {
  background: $renk_koyumavi;
  color: #fff;

  .txtBulten {
    border-color: rgba(#ffffff, 0.75);
    color: #fff;

    &::placeholder {
      color: rgba(#ffffff, 0.75);
    }

    &:focus,
    &:hover {
      border-color: $renk_mavi;
    }
  }

  .custom-control-label {
    color: #fff;

    a {
      color: #fff;
    }
  }

  &__ust {
    border-bottom: 1px solid $renksb;
    background: $renkss-2;
    margin-bottom: 30px;

    .container-fluid {
      @extend .flex;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__alt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;
  }
}

.footer-nav {
  @extend .flex;
  background: $renk_mavi;

  &__link {
    @extend .efekt;
    display: block;
    padding: 15px 10px;
    width: 33.3333%;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;

    &:nth-of-type(2) {
      border-right: 1px solid $renksb-4;
      border-left: 1px solid $renksb-4;
    }

    &:hover {
      background: $renksb-2;
      color: $renk_mavi;
    }
  }
}

.footer-sosyal {
  color: $renk_mavi;

  strong {
    font-weight: 500;
  }

  a {
    @include efekt(color);
    display: inline-block;
    margin-left: 15px;
    color: $renk_mavi;
    text-decoration: none;
    font-size: 1.2rem;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      color: #8fddcf;
    }
  }
}

.footer-adres {
  width: 350px;
  font-size: 0.9rem;

  a {
    display: inline-block;
    margin-bottom: 10px;
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-line {
  margin: 25px 0;
  width: 70px;
  height: 1px;
  border: none;
  background: $renkss;
}

.footer-menu {
  width: 180px;

  &--mobile {
    display: none;
  }

  a {
    @include efekt(color);
    display: block;
    margin-bottom: 25px;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;

    &:hover {
      color: $renk_mavi;
    }
  }
}

.footer-bulten {
  width: 350px;

  .btn {
    color: #fff;
  }

  &__baslik {
    display: block;
    margin-bottom: 10px;
    font-size: 0.9rem;
  }
}

.footer-genel {
  a {
    display: inline-block;
    padding: 4px 0;
    color: #fff;
    text-decoration: none;
    font-size: 0.9rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

.hero {
  padding-top: 250px;

  &__title {
    color: $renk_koyumavi;
    font-weight: 300;
    font-size: 4rem;
    font-family: $libre;

    small {
      display: block;
      font-weight: 300;
      font-size: 1.2rem;
      font-family: $mont;
    }
  }
}

.tab-nav--category {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $renkss;

  .tab-link {
    @include efekt(color);
    display: inline-block;
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid $renkss;
    color: #8e8e8e;
    text-decoration: none;
    font-weight: 400;
    font-size: 1.2rem;

    &:last-child {
      border: none;
    }

    &:hover {
      color: $renk_mavi;
    }

    &.active {
      color: $renk_koyumavi;
      font-weight: 600;
    }
  }
}

.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__left {
    padding: 5%;
    width: 40%;
    background: $renk_koyumavi;
    color: #fff;
    font-size: 1.1rem;
  }

  &__map {
    width: 60%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    display: block;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 2rem;
  }

  &__text {
    display: block;
  }

  &__info {
    margin: 100px 0;

    a {
      @include efekt(color);
      color: #fff;
      text-decoration: none;

      &:hover {
        color: $renk_mavi;
      }
    }
  }
}

.road-map {
  @include efekt(color);
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.3rem;

  .bi {
    margin-left: 5px;
  }

  &:hover {
    color: $renk_mavi;
  }
}

.contact-text {
  font-weight: 300;
  font-size: 1.2rem;
}

.formContact {
  .form {
    position: relative;
    display: block;
    padding: 30px;
    background: #f6f7fb;

    &__baslik {
      display: inline-block;
      font-weight: 600;
      font-size: 2.5rem;
      line-height: 1;
    }

    &__flex {
      @extend .flex;
      justify-content: flex-start;
      margin: 30px 0;
      width: 100%;

      .pf {
        display: inline-block;
        margin-right: 30px;
        font-size: 1rem;
      }
    }

    &__ic {
      border: 1px solid $renk_cizgi;
    }

    &__ust {
      @extend .flex;
      align-items: flex-start;
    }

    &__item {
      @extend .flex;
      padding: 15px;
      width: 100%;
      border-right: 1px solid $renk_cizgi;
      border-bottom: 1px solid $renk_cizgi;

      &--wide {
        width: 100%;

        label {
          width: 160px;
        }

        input {
          width: calc(100% - 190px) !important;
        }
      }

      &:nth-of-type(2n) {
        border-right: none;
      }

      .form__text {
        width: 64%;
        -webkit-appearence: none;
      }
    }

    &__label {
      padding-right: 15px;
      width: 36%;
      font-size: 1rem;
      font-family: $libre;
    }

    &__text {
      display: block;
      width: 100%;
      height: 40px;
      outline: none;
      border: none;
      border-bottom: 1px solid transparent;
      background: none;
      font-size: 0.9rem;
      line-height: 40px;

      &:focus,
      &:hover {
        border-color: $renk_mavi;
      }
    }

    &__alt {
      padding: 15px;
    }

    &__sartlar {
      padding: 15px 15px 30px;
      border-top: 1px solid #dfdfdf;

      .yazi {
        color: #828282;
        font-size: 0.8rem;
      }
    }

    &__top {
      padding-left: 60px;
    }

    &__yaprak {
      position: absolute;

      &--1 {
        top: -80px;
        left: -80px;
      }

      &--2 {
        top: 42%;
        right: -120px;
      }
    }
  }
}

.project-hero {
  position: relative;

  .slider-detail {
    bottom: 70px;
    width: 100%;
  }

  .down-arrow {
    margin-top: 60px;
  }

  &__img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }
}

.page--detail {
  padding-top: 150px;
}

.features-slider {
  position: relative;

  &:before {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    background: $renk_koyumavi;
    content: "";
  }

  .swiper-buttons {
    position: absolute;
    bottom: 70px;
    left: 0;
    z-index: 10;
    padding-right: 50px;
    width: 35%;
    text-align: right;
  }
}

.feature {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  color: #fff;

  &__left {
    padding: 10% 50px 90px;
    width: 35%;

    .title {
      font-weight: 200;

      small {
        margin-top: 10px;
      }
    }
  }

  &__right {
    width: 63%;
  }
}

#types-slider {
  position: relative;
  padding-bottom: 100px;

  .slider-nav {
    width: 100%;
  }

  .swiper-buttons {
    svg {
      line,
      path {
        @include efekt(stroke);
        stroke: $renk_koyumavi;
      }

      &:hover {
        line,
        path {
          stroke: $renk_mavi;
        }
      }
    }
  }

  .swiper-pagination-progressbar {
    background: $renk_koyumavi;
  }

  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: $renk_mavi;
  }

  .swiper-pagination-progressbar.swiper-pagination-horizontal {
    left: 160px;
    width: calc(100% - 200px);
  }
}

.types {
  position: relative;
  padding-bottom: 50px;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80%;
    background: #f4f4f4;
    content: "";
  }

  .container-fluid {
    padding-right: 0;
  }

  &__flex {
    @extend .flex;
  }

  &__left {
    width: 250px;
  }

  &__right {
    width: calc(100% - 250px);
  }
}

.swiper-button {
  user-select: none;
}

.flat {
  padding: 15px;
  border: 1px solid #dfdfdf;
  background: #fff;

  &__img {}

  &__top {
    @extend .flex;
    margin-bottom: 25px;
  }

  &__title {
    font-weight: 500;
    font-size: 1.5rem;
  }

  &__details {
    width: 50%;
  }

  &__divider {
    width: 1px;
    height: 40px;
    background: #dfdfdf;
  }

  &__detail {
    @extend .flex;
    margin-bottom: 2px;
    font-size: 0.9rem;
  }

  &__list {
    margin-bottom: 25px;

    li {
      padding: 5px 0;
      border-bottom: 1px solid #dfdfdf;

      &:first-child {
        border-top: 1px solid #dfdfdf;
      }
    }
  }
}

.get-quote {
  @include efekt(color);
  color: $renk_koyumavi;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.2rem;

  svg {
    position: relative;
    top: -2px;
    margin-left: 4px;
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: $renk_mavi;
  }
}

.product__carousel .swiper-button-next.swiper-button-white,
.product__carousel .swiper-button-prev.swiper-button-white {
  color: $renk_mavi !important;
}

.product__carousel {
  display: block;
  margin-top: 0;
  max-width: 100%;
}

.product__carousel a {
  display: block;
}

.gallery-thumbs {
  overflow: hidden;
  margin-top: 15px;
}

.gallery-top {
  overflow: hidden;
}

.product__carousel .gallery-top {
  margin-bottom: 5px;
}

.product__carousel .gallery-top .swiper-slide {
  position: relative;
  overflow: hidden;
}

.product__carousel .gallery-top .swiper-slide a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.product__carousel .gallery-top .swiper-slide a img {
  width: 100%;
}

.product__carousel .gallery-top .swiper-slide .easyzoom-flyout img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.product__carousel .swiper-button-next.swiper-button-white,
.product__carousel .swiper-button-prev.swiper-button-white {
  color: #ff3720;
}

.product__carousel .gallery-thumbs .swiper-slide {
  position: relative;
  overflow: hidden;
  height: calc(100% - 2px);
  border: 1px solid #ebebeb;
  border-radius: 3px;
  cursor: pointer;
  transition: border 0.15s linear;
}

.product__carousel .gallery-thumbs .swiper-slide.swiper-slide-thumb-active {
  border-color: $renk_mavi;
}

.product__carousel .gallery-thumbs .swiper-slide img {
  width: 100%;
  height: 90px;
  object-fit: cover;
}

.map-section {
  margin-top: 75px;
  margin-bottom: 75px;
  padding: 100px 0;
  background: #f4f4f4;
}

.map {
  iframe {
    width: 100%;
    height: 70vh;
  }
}

.text {
  line-height: 1.7;

  p {
    display: block;
    margin-bottom: 30px;
  }
}

.about-entry {
  &__bottom {
    position: relative;
    margin-top: 100px;
    padding-bottom: 100px;
    color: #fff;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 80%;
      background: $renk_koyumavi;
      content: '';
    }

    .container-fluid {
      position: relative;
      z-index: 2;
    }
  }

  &__img {
    img {
      width: 100%;
    }
  }
}

.h-bolum {
  padding: 90px 0;
}

.hakkimizda-ust {
  background: #ECF2FF;
}

.h-baslik {
  display: block;
  padding-bottom: 100px;
  color: $renk_koyumavi;
  font-weight: 700;
  font-size: 60px;
  line-height: 1.4;
}

.hu-yazi {
  align-self: flex-end;

  .hu-w {
    display: block;
    padding-top: 60px;
    padding-left: 30px;
    border-left: 2px solid $renk_mavi;
    font-size: 17px;
    font-family: $libre;
    line-height: 1.6;
  }
}

.h-resim {
  position: absolute;
  right: 0;
  display: block;
  width: 100%;
}

.hakkimizda-resim {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.h-bolum-2 {
  position: relative;
  overflow: hidden;
}

.h-bolum-23 {
  .row {
    flex-direction: row-reverse;
  }
}

.h-yazi {
  padding: 60px 30px;
  font-size: 17px;
  font-family: $libre;
  line-height: 1.6;

  p {
    position: relative;

    &:before {
      display: block;
      margin: 25px 0;
      width: 50px;
      height: 3px;
      background: $renk_mavi;
      content: '';
    }
  }
}

.hr-2 {
  left: 0;
}
/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline {
  overflow-x: hidden;
  padding: 90px 0;
  background: #ECF2FF;
  white-space: nowrap;

  time {
    color: $renk_koyumavi;
    font-weight: 600;
  }
}

.timeline ol {
  padding-top: 360px;
  padding-bottom: 90px;
  width: 100vw;
  font-size: 0;
  transition: all 1s;
}

.timeline ol li {
  position: relative;
  display: inline-block;
  width: 360px;
  height: 3px;
  background: #CECECE;
  list-style-type: none;

  &:first-child {
    width: 120px;
  }
}

.timeline ol li:last-child {
  width: 100%;
}

.timeline ol li:not(:first-child) {
  margin-left: 24px;
}

.timeline ol li:not(:last-child)::after {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 100%;
  width: 24px;
  height: 24px;
  border: 2px solid #CECECE;
  border-radius: 50%;
  background: #fff;
  content: '';
  transform: translateY(-50%);
}

.timeline ol li div {
  @extend .efekt;
  position: absolute;
  top: -26px !important;
  left: calc(100% + 7px);
  padding: 30px;
  width: 280px;
  border: 3px solid #B2A27E;
  background: white;
  color: black;
  white-space: normal;
  font-size: 14px;
  font-family: $libre;
  line-height: 1.6;
  transform: translateY(-100%) !important;
}

.timeline ol li div::before {
  position: absolute;
  top: 100%;
  top: 100%;
  left: -3px;
  width: 0;
  height: 0;
  border-width: 12px 12px 0 0;
  border-style: solid;
  border-color: #B2A27E transparent transparent transparent;
  content: '';
}

.timeline time {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 1.2rem;
}
/* TIMELINE ARROWS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline .arrows {
  display: block;
  margin-bottom: 20px;
  padding-right: 30px;
  text-align: right;

  button {
    @extend .efekt;
    width: 35px;
    height: 35px;
    outline: none;
    border: none;
    border-radius: 50%;
    background: $renk_koyumavi;
    color: #fff;
    text-align: center;
    font-size: 19px;
    line-height: 35px;

    svg {
      position: relative;
      top: -2px;
      width: 18px;
      height: 18px;
    }

    &:hover {
      background: $renk_mavi;
      color: #fff;
      cursor: pointer;
    }
  }
}

.timeline .arrows .arrow__prev {
  margin-right: 20px;
}

.timeline .disabled {
  opacity: 0.5;
}

.timeline .arrows img {
  width: 45px;
  height: 45px;
}

.about-figure {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &__icon {}

  &__title {
    margin-left: 30px;
    padding-top: 120px;
    padding-left: 30px;
    border-left: 1px solid $renksb-4;
    font-weight: 600;
    font-size: 5vw;

    small {
      display: block;
      font-weight: 300;
      font-size: 1.3rem;
    }
  }
}

.gallery-wrapper {
  position: relative;
}

.about-message {
  padding: 70px 0;
  background: #f4f4f4;

  p {
    display: block;
    margin-bottom: 30px;
  }
}

.references {
  padding: 70px 0;
}

.reference-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  li {
    width: 20%;
    border: 1px solid #dfdfdf;
    margin-left: -1px;
    margin-top: -1px;
    text-align: center;
    padding: 30px 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.slider-detail {
  z-index: 3;
}

.sticky-buttons {
  position: sticky;
  padding: 15px;
  bottom: 0;
  margin-left: auto;
  width: 250px;
  text-align: right;
  display: flex;
  align-items: center;
  z-index: 99;
  justify-content: flex-end;
}

.sticky-button {
  width: 55px;
  height: 55px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  border-radius: 50%;
  position: relative;
  text-decoration: none;
  color: #fff;
  font-size: 1.3rem;
  transition: all 0.3s;
}

.sticky-button svg {
  width: 24px;
  height: 24px;
}

.sticky-button:hover {
  box-shadow: 0 0 0 6px rgba(255, 255, 255, .4);
}

.sticky-button--phone {
  background: #02a758;
}

.sticky-button--phone:hover {
  background: #00d16d;
}

.sticky-button--whatsapp {
  background: #1fb69f;
}

.sticky-button--whatsapp:hover {
  background: #06d5b5;
}

.sticky-button--instagram {
  background: #1a6ed8;
}

.sticky-button--instagram:hover {
  background: #1e81fe;
}

.phone--mobile{
  display: none;
  text-shadow: none;
  padding: 15px;

  a{
    color: $renk_mavi;

    &:hover{
      color: $renk_koyumavi;
    }
  }
}
@import "style1200", "style992", "style768","mobil-nav", "style576";
