@media (max-width:1450px) {
  .flat__title {
    font-size: 1.1rem;
  }

  .feature__left .title small {
    font-size: 1rem;
  }

  .about-figure__icon {
    width: 80px;
  }

  .about-figure__title {
    font-size: 4vw;

    small {
      font-size: 1.5vw;
    }
  }

  .hero {
    padding-top: 150px;
  }

  .slider-detail__title {
    font-size: 2.5rem;
  }

  .slider-detail__text {
    font-size: 1.5rem;
  }

  .slider-detail__logo {
    width: 150px;
  }

  .down-arrow {
    width: 60px;
  }

  .project-hero .down-arrow {
    margin-top: 30px;
  }

  .project-hero__img {
    height: 70vh;
  }


  .swiper-pagination-progressbar.swiper-pagination-horizontal {
    left: 160px;
    width: calc(100% - 360px);
  }

  .form-wrapper {
    width: 400px;

    .mb30 {
      margin-bottom: 15px;
    }

    .mt30 {
      margin-top: 15px;
    }

    .custom-control-label {
      font-size: 0.7rem;
    }
  }

  .btn {
    font-size: 0.9rem;
  }

  .project__title {
    font-size: 1.5rem;
  }

  .link {
    font-weight: 400;
    font-size: 1.2rem;
  }
}
@media (max-width:1200px) {
  .logo {
    width: 200px;
  }

  .menu__link {
    height: 80px;
    font-size: 1rem;
    line-height: 80px;
  }

  .lang-select {
    font-size: 0.9rem;
  }

  .header__flex {
    height: 80px;
  }

  .phone {
    font-size: 0.9rem;
  }

  .container,
  .container-fluid {
    padding-right: 50px;
    padding-left: 50px;
  }

  .form-wrapper {
    right: 50px;
  }

  .phone--header {
    right: 50px;
  }

  .slider-detail__title {
    font-size: 2rem;
  }

  .slider-detail__text {
    font-size: 1.2rem;
  }

  .link {
    font-size: 1.1rem;
  }

  .swiper-pagination-progressbar.swiper-pagination-horizontal {
    left: 130px;
    width: calc(100% - 320px);
  }

  .btn {
    padding: 0.375rem 1.25rem;
  }

  .project__title {
    font-size: 1.4rem;
  }

  .project__logo {
    width: 90px !important;
  }

  .title small {
    font-size: 1.1rem;
  }

  .footer-logo {
    width: 180px;
  }

  .footer-menu {
    display: none;

    &--mobile {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;
      width: 100%;
      border-bottom: 1px solid rgba(255,255,255,.1);

      a {
        margin-bottom: 0;
        font-size: 1rem;
      }
    }
  }

  .footer__ust {
    margin-bottom: 0;
  }

  .footer__alt {
    margin-top: 30px;
  }

  .footer-genel {
    .fz1 {
      font-size: 0.9rem;
    }

    a {
      font-size: 0.8rem;
    }
  }

  .footer-adres {
    width: 300px;
    font-size: 0.8rem;
  }

  .footer-bulten__baslik {
    font-size: 0.8rem;
  }

  .footer-bulten {
    width: 300px;
  }

  .tab-nav--category .tab-link {
    margin-right: 15px;
    padding-right: 15px;
    font-size: 1rem;
  }

  .contact__title {
    font-size: 1.4rem;
  }

  .contact__text {
    font-size: 0.9rem;
  }

  .contact__info {
    font-size: 0.9rem;
  }

  .road-map {
    font-size: 1rem;
  }

  .about-figure__icon {
    width: 70px;
  }

  .slider-detail__logo {
    width: 100px;
  }

  .feature__left .title small {
    font-size: 0.9rem;
  }

  .flat__title {
    margin-bottom: 15px;
    padding-bottom: 15px;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
  }

  .flat__divider {
    display: none;
  }

  .flat__details {
    width: 100%;
  }

  .get-quote {
    font-size: 1rem;
  }

  .contact-text {
    font-size: 1rem;
  }

  .slider-nav{
    bottom: 0;
  }
}
