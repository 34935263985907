.title {
  font-size: 2.5rem;
  display: block;
  font-family: $libre;

  small{
    font-family: $mont;
    font-weight: 300;
    font-size: 1.2rem;
    display: block;
  }

  &__sub{
    color: $renk_mavi;
    display: block;
    font-family: $mont;
    font-weight: 400;
  }

  &--medium {
    font-size: 2rem;
  }

  &--smaller {
    font-size: 1.5rem;
  }
}
@media (max-width:1200px) {
  .title {
    font-size: 2.2rem;

    &--medium {
      font-size: 1.7rem;
    }

    &--smaller {
      font-size: 1.2rem;
    }
  }
}
@media (max-width: 992px) {
  .title {
    font-size: 2rem;

    &--medium {
      font-size: 1.5rem;
    }

    &--smaller {
      font-size: 1.1rem;
    }
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 1.7rem;

    &--medium {
      font-size: 1.3rem;
    }

    &--smaller {
      font-size: 1.1rem;
    }
  }
}
